import { default as financingXQel3UzoooMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as indexwMBKG8H8PkMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyzpM7urNWuHMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamJILShVmiusMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as copperas_45cove_45area3EKpT2hr4ZMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areaupt3qG1BkeMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45area0rsWSIRf0PMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaN4j34dU4fsMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45area0pNkRiUnPBMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areaMOeEsQqaqXMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45arearHCNgZFDNYMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areaSzVMmszKcyMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93X1ydJbyou5Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexwcqBeeN2ePMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexvlrPcnFyjgMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_934QWf1dJMt2Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as index8dNhdYUMr6Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as park_45meadowsZG64EhkoxjMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue?macro=true";
import { default as rosenthal_45estatesUGBNKcQUjbMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue?macro=true";
import { default as templateQZbfUED2XtMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/template.vue?macro=true";
import { default as indexeAGoyXCf2QMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45questiondKKJVkD3gpMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesk3oAnLC6icMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqnZFKOeGv5PMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesYEWrf6W0NyMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexkx1UDs2d6TMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locations9JHimkQkWJMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiry45Z4HXaxpvMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offersNStOndvnzfMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexAM5SJMM9feMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexcFBRlS8ywyMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/events/index.vue?macro=true";
import { default as index4arCJeM7nuMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/gallery/index.vue?macro=true";
import { default as indexYMV2mSY58QMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexgKlC6F3uDFMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as index2e4cgdXKHIMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionMldnzGGQCIMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as _91testimonial_93KacxW8PffSMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as index8GUQIESLxGMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexFOE3AnVpTFMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as _91_46_46_46slug_93s6LIXPMLXgMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as indexOFhUK1ZDg9Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favoritessUgOyxXgMGMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45search4swsncHCsmMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communitiesf2zUNbAtp3Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as duplex_45collection7fkKBdn8lvMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue?macro=true";
import { default as heritage_45collectionR8Z8apRNOOMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue?macro=true";
import { default as heritagei_45collectionNNFe3BUkMJMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue?macro=true";
import { default as legacy_45collectionipOsdxmhDsMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue?macro=true";
import { default as legacyii_45collectionaQZYSsO5w1Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue?macro=true";
import { default as pioneer_45collectionS90kixNon6Meta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue?macro=true";
import { default as townhome_45collection3BgoiC14SLMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue?macro=true";
import { default as indexhJK15qB9EZMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsjdKRa56aAWMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as index38fQsLVBvHMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandboxBXT8SGlm3WMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserve1UGPMn8JzmMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creekuD1zRMcsnCMeta } from "/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: "about-us-financing",
    path: "/about-us/financing",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-story",
    path: "/about-us/our-story",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: "about-us-our-team",
    path: "/about-us/our-team",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: "areas-copperas-cove-area",
    path: "/areas/copperas-cove-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-georgetown-area",
    path: "/areas/georgetown-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-killeen-area",
    path: "/areas/killeen-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-lorena-area",
    path: "/areas/lorena-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-nolanville-area",
    path: "/areas/nolanville-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-salado-area",
    path: "/areas/salado-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-temple-area",
    path: "/areas/temple-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: "areas-troy-area",
    path: "/areas/troy-area",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: "blog-post",
    path: "/blog/:post()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: "blog-category-category",
    path: "/blog/category/:category()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "campaigns-slug",
    path: "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_934QWf1dJMt2Meta || {},
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-park-meadows",
    path: "/coming-soon/park-meadows",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/park-meadows.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-rosenthal-estates",
    path: "/coming-soon/rosenthal-estates",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/rosenthal-estates.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon-template",
    path: "/coming-soon/template",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/coming-soon/template.vue").then(m => m.default || m)
  },
  {
    name: "communities-community",
    path: "/communities/:community()",
    meta: indexeAGoyXCf2QMeta || {},
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-ask-a-question",
    path: "/contact-us/ask-a-question",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-career-opportunities",
    path: "/contact-us/career-opportunities",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-faq",
    path: "/contact-us/faq",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-homeowner-resources",
    path: "/contact-us/homeowner-resources",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-our-locations",
    path: "/contact-us/our-locations",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: "contact-us-trade-partner-inquiry",
    path: "/contact-us/trade-partner-inquiry",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: "current-offers",
    path: "/current-offers",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: "events-event",
    path: "/events/:event()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-location-community",
    path: "/homes/:location()/:community()",
    meta: indexYMV2mSY58QMeta || {},
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: "homes-plans-plan",
    path: "/homes/plans/:plan()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "land-acquisition",
    path: "/land-acquisition",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-testimonial",
    path: "/lifestyle/reviews/:testimonial()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews-category-category",
    path: "/lifestyle/reviews/category/:category()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "lifestyle-reviews",
    path: "/lifestyle/reviews",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "marshallfire-slug",
    path: "/marshallfire/:slug(.*)*",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "models-model",
    path: "/models/:model()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-favorites",
    path: "/my-favorites",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: "new-home-search",
    path: "/new-home-search",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-communities",
    path: "/our-homes/communities",
    alias: ["/our-homes/model-homes/","/our-homes/quick-move-in-homes/"],
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-duplex-collection",
    path: "/our-homes/home-collections/duplex-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/duplex-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-heritage-collection",
    path: "/our-homes/home-collections/heritage-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritage-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-heritagei-collection",
    path: "/our-homes/home-collections/heritagei-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/heritagei-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-legacy-collection",
    path: "/our-homes/home-collections/legacy-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacy-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-legacyii-collection",
    path: "/our-homes/home-collections/legacyii-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/legacyii-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-pioneer-collection",
    path: "/our-homes/home-collections/pioneer-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/pioneer-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes-home-collections-townhome-collection",
    path: "/our-homes/home-collections/townhome-collection",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/home-collections/townhome-collection.vue").then(m => m.default || m)
  },
  {
    name: "our-homes",
    path: "/our-homes",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: "privacyterms",
    path: "/privacyterms",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: "qmi-qmi",
    path: "/qmi/:qmi()",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: "the-preserve",
    path: "/the-preserve",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: "the-ridge-at-knob-creek",
    path: "/the-ridge-at-knob-creek",
    component: () => import("/codebuild/output/src1601455389/src/flintrock/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]